import React,{useState} from "react"
import { Link, graphql } from "gatsby"
import {Row, Col} from "react-bootstrap"
import Layout from "../components/layout-password"
import CatButtons from "../components/filter-button"
import PortfolioList from "../components/portflio-items"
import Seo from "../components/seo"



const BlogPortfolioSecretIndex = ({data,  pageContext: { nextPagePath, previousPagePath },}) => {
  const posts = data.allWpPost.nodes
  const allCategories = new Set();
  posts.map(post=> {
    post.categories.nodes.filter( cat => {
        if(allCategories.has(cat.name)) {
          return false
        }
        let catname = cat.name
        if(cat.name === "Portfolio")
        {
          catname = "All"
        }
        allCategories.add(catname)
        return true
      })
      return true
  })
  allCategories.delete('Secret')
  const [menuItem, setMenuItem] = useState(posts);
  const [buttons] = useState(allCategories);
  const btnArray = [...buttons];
  btnArray.sort((a, b) =>
    a.localeCompare(b)//using String.prototype.localCompare()
  );
  const filter = (button) =>{
    const currentClass = document.getElementsByClassName("btn-filter");
    for (let i = 0; i < currentClass.length; i++) {
      currentClass[i].classList.remove("active");
      if(currentClass[i].textContent === button) {
        currentClass[i].classList.toggle("active");
        console.log(currentClass[i]);
      }
    }
    if(button === 'All'){
      setMenuItem(posts);
      return;
    }
    const filteredData = []
    posts.filter(post => (post.categories.nodes.map( cat => {
      if(cat.name === button ){
        filteredData.push(post)
      }
      return true;
    })))
    setMenuItem(filteredData)
  }


  return (
      <Layout>
      <Seo title="All Portfolio" />
      <Row>
        <Col lg={12} xs={12}>
          <h2 className="underlined">Portfolio</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <CatButtons button={btnArray} filter={filter} />
        </Col>
      </Row>
      <Row  md={3} className="part-portolio">
        <PortfolioList menuItem={menuItem} />
      </Row>
      <Row className="nav-bottoms">
        <Col  xs={12} lg={6} className="text-right">
        {previousPagePath && (
          <>
            <Link to={previousPagePath}>Previous page</Link>
            <br />
          </>
        )}
        </Col>
        <Col  xs={12} lg={6}>
        {nextPagePath && <Link to={nextPagePath}>Next page</Link>}
        </Col>
      </Row>
      </Layout>
  )
}

export default BlogPortfolioSecretIndex

export const pageQuery = graphql`
  query WordPressPortfolioSecretArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
      filter: {categories: {nodes: {elemMatch: {slug: {in:["portfolio","portfolio-secret"]}}}}}
    ) {
      nodes {
        id
        uri
        title
        categories {
          nodes {
            name
            slug
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`
