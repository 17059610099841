import React, { useEffect } from 'react';
import { navigate } from "gatsby"
import Header from "../components/header-password"
import {Container, Row, Col} from "react-bootstrap"
import { isLoggedIn } from "../utils/auth"

const LayoutPassword = ({ children }) => {
  
  useEffect(() => {
      // 👇 Redirects to about page, note the `replace: true`
      if (!isLoggedIn()) {
        // If we’re not logged in, redirect to the home page.
        navigate('/login/', { replace: true });
      }
  }, []);
  return (
    <Container>
      <Header></Header>

      <main>{children}</main>

      <footer>  
        <Row>
          <Col>
            <p>© {new Date().getFullYear()} Kaan ATA  All rights reserved.</p>
            <p> Built with {` `} <a href="https://www.gatsbyjs.com" target="_blank" rel="noreferrer">Gatsby</a>
            {` `}
            And <a href="https://wordpress.org/" target="_blank" rel="noreferrer" >WordPress</a></p>
          </Col>
          <Col></Col>
        </Row>
      </footer>
    </Container>
  )
}

export default LayoutPassword
