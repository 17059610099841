import React from 'react'

function Button({button, filter}) {
    return (
        <>
            {
                button.map((cat, index) => {
                    return <button key={index} onClick={() => filter(cat)} className={`btn btn-filter ${cat === "All" ? "active" : ""}`}>{cat}</button>
                })
            }
        </>
    )
}

export default Button;