import React from 'react'
import {Link} from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import {Col, Image} from "react-bootstrap"
function PortfolioList({menuItem}) {
    return (
        <>
            {
                menuItem.map(post => {
                    const featuredImage = {
                        fluid: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
                        public: post.featuredImage?.node?.localFile.publicURL,
                        alt: post.featuredImage?.node?.altText || ``,
                    }
                    return (
                    <Col key={post.uri}>
                        <Link to={post.uri}>
                            <h3>{parse(post.title)}</h3>
                            {featuredImage.fluid ? (
                                <GatsbyImage image={featuredImage.fluid} alt={featuredImage.alt} />
                            ):(
                                <Image src={featuredImage.public} alt={featuredImage.alt}/>
                            )}
                        </Link>
                    </Col>        
                    )
                })
            }
        </>
    )
}

export default PortfolioList;